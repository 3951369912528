import React, { useState } from 'react';
import ZoomLogo from '../images/zoom_logo.png'
import ZoomDoc from '../images/zoomDoc.png'
import Modal from './Modal'

function Zoom() {
  const [openModal, setOpenModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');

  return (
    <div className="container text-center py-5">
      <div className="row py-5">
        <div className="col-sm py-5">
          <img src={ZoomLogo} alt="zoom" className='img-fluid img-meet' name={ZoomLogo} onClick={(e) => {
            setImgUrl(e.target.name)
            setOpenModal(true)

          }} />


          <a href="https://ruts.zoom.us/" className="nav-link text-dark hover-underline-animation"><i className="fa fa-link text-info"></i> Link เข้าใช้งาน ZOOM Meeting https://ruts.zoom.us</a>
          <a href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/ZOOM.pdf" target="_blank" rel='noreferrer' className="nav-link text-dark hover-underline-animation"><i className="fa fa-link text-info"></i> Link Download เอกสาร PDF</a>
        </div>
        <div className="col-sm py-5">
          <a href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2022/01/zoom-recording.pdf" target="_blank" rel='noreferrer'><img src={ZoomDoc} alt="zoom document" width={450} className="img-fluid rounded shadow-lg img-meet" /></a>
          <a href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2022/01/zoom-recording.pdf" className='nav-link text-dark hover-underline-animation' target="_blank" rel="noreferrer"><i className="fa fa-download"></i> Download เอกสารคู่มือการใช้งานโปรแกรม Zoom</a>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <p className="text-center p-3 mt-2 mb-2 bg-primary text-light rounded-pill shadow"><i class="fa-solid fa-video"></i> การใช้งาน Zoom https://ruts.zoom.us</p>
        </div>
      </div>

      <div className="row py-3">
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/01_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/02_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/03_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/04_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/05_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/06_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/07_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/08_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/09_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/010_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/011_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/012_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/013_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/014_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
        <div className="col-md mb-2">
          <img src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/015_Zoom-819x1024-640x480.jpg" alt="" className='rounded shadow cropped1 img-meet' onClick={(e) => {
            setImgUrl(e.target.src)
            setOpenModal(true)

          }} />
        </div>
      </div>
      {openModal && <Modal closeModal={setOpenModal} imgUrl={imgUrl} />}
      {/* {openModal && <Modal closeModal={setOpenModal} imgUrl={imgUrl} />} */}
    </div>
  )
}

export default Zoom