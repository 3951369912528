import React from 'react'
import mTeam from '../images/mTeam.png'
import teamTeacher from '../images/teamTeacher.png'
import teamStudent from '../images/teamStudent.png'
import teamBegin from '../images/teamBegin.png'
import teamMobile from '../images/teamMobile.png'

const Team = () => {
    return (
        <div className='container mt-5 p-5'>
            <div className="row">
                <div className="col-lg-6 mx-auto text-center">
                    <img src={mTeam} alt="" />
                </div>
            </div>
            <div className="row mt-2 d-flex justify-content-around align-items-center">
                <div className="col-lg-3 col-md-6 col-sm">
                    <a href="https://arit.rmutsv.ac.th/sites/default/files/Microsoft%20Team-Techer.pdf" target='_blank'>

                        <img src={teamTeacher} alt="" className='teamIcon' />
                    </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm">
                    <a href="https://arit.rmutsv.ac.th/sites/default/files/Microsoft%20Team-Studen.pdf" target='_blank'>

                        <img src={teamStudent} alt="" className='teamIcon' />
                    </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm">
                    <a href="https://arit.rmutsv.ac.th/sites/default/files/Microsoft%20Team-Basic.pdf" target='_blank'>

                        <img src={teamBegin} alt="" className='teamIcon' />
                    </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm">
                    <a href="https://arit.rmutsv.ac.th/sites/default/files/Microsoft%20Team-Mobile.pdf" target='_blank'>

                        <img src={teamMobile} alt="" className='teamIcon' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Team