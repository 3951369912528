import React from "react";
import GoogleLogo from "../images/google.png";
import MicrosoftLogo from "../images/Microsoft-Logo.png";
import ZoomLogo from "../images/zoom_logo.png";
import { Helmet } from 'react-helmet-async'
import Fade from 'react-reveal/Fade'
import TeamPoster from '../images/team.jpeg';
import MailPoster from '../images/mail.jpeg';

export default function Welcome() {
  return (
    <>
      <Helmet>
        <title>Online Learning RMUTSV</title>
        <meta
          name="description"
          content="Online learning สำนักวิทยบริการ มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์"
        />
        <meta
          name="description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta name="robots" content="nofollow, noindex" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="หน้าแรก - RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta
          property="og:description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta
          property="og:url"
          content="https://www.online-learning.rmutsv.ac.th/"
        />
        <meta
          property="og:site_name"
          content="RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta property="og:updated_time" content="2022-05-12T09:28:38+07:00" />
        <meta
          property="article:published_time"
          content="2020-03-19T18:48:33+07:00"
        />
        <meta
          property="article:modified_time"
          content="2022-05-12T09:28:38+07:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="หน้าแรก - RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta
          name="twitter:description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="admin" />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="Less than a minute" />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="container welcome-img">
        {/* Fade  */}
        <Fade top>
          <img
            src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/ruts-online-2048x853.jpg"
            alt="google"
            className="img-fluid rounded shadow"
          />
        </Fade>
        <div className="row">
          <div className="col-lg-6 col-sm">
            <Fade left>
              <img
                src={TeamPoster}
                alt="team"
                className="img-fluid rounded shadow mt-3"
              />
            </Fade>
          </div>
          <div className="col-lg-6 col-sm">
            <Fade right>
              <img
                src={MailPoster}
                alt="mail"
                className="img-fluid rounded shadow mt-3"
              />
            </Fade>
          </div>
        </div>
        <div className="row">
          <Fade left>
            <div className="col-md">
              <img
                src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2021/07/Meet-Zoom-Team-1024x542.png"
                alt="zoom"
                className="img-fluid rounded shadow mt-3"
              />
            </div>
          </Fade>
          <Fade right>
            <div className="col-md mt-3 mb-3">
              <h5 className="text-shadow">
                สำนักวิทยบริการและเทคโนโลยีสารสนเทศ
              </h5>
              <p>
                &#160; มีความยินดีนำเสนอ ศูนย์การเรียนรู้แบบออนไลน์
                เพื่อเป็นช่องทางการเรียนรู้ในรูปแบบออนไลน์
                ซึ่งเป็นศูนย์ที่รวบรวบข้อมูล วิธีการใช้งานโปรแกรมที่ทันสมัย
                เพื่อให้ผู้ที่สนใจได้เข้ามาหาความรู้เพื่อทำความเข้าใจ
                เพื่อประสิทธิภาพในการเรียนการสอนได้ดียิ่งขึ้น...
              </p>
              <hr />
              <div className="row d-flex d-flex ">
                <div className="col-md text-center mb-3">
                  <img
                    src={GoogleLogo}
                    className="thumbnail rounded-pill shadow "
                    width="150"
                    alt=""
                  />
                </div>
                <div className="col-md text-center mb-5">
                  <img
                    src={MicrosoftLogo}
                    className="thumbnail rounded shadow "
                    width="150"
                    alt=""
                  />
                </div>
                <div className="col-md text-center d-flex justify-content-center align-items-center mb-2">
                  <img
                    src={ZoomLogo}
                    className="thumbnail rounded-pill shadow "
                    width="150"
                    alt=""
                  />
                </div>
              </div>

              {/* <div className="row">

            <div className="col-sm mt-2">
              <img src={google_microsof} className="img-fluid rounded shadow" alt="" />
            </div>
          </div>
          <div className="row">

            <div className="col-sm mt-2">
              <img src={google_microsof_t} className="img-fluid rounded shadow" alt="" />
            </div>
          </div> */}

              <hr />
              {/* <div className="row">
            <div className="col-sm text-center">
              <img src={ZoomLogo} className="thumbnail rounded shadow " width="150" alt="" />
            </div>
            <div className="col-sm mt-2">
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum, quasi!</p>
            </div>
          </div> */}
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
}
