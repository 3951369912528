import React from 'react'
import { Helmet } from 'react-helmet-async'
import gCalendar1 from '../images/gCalendar1.jpg';
import gCalendar2 from '../images/gCalendar2.jpg';
import Fade from 'react-reveal/Fade'
const GoogleCalendar = () => {
    return (
      <>
        <Helmet>
          <title>Google tools</title>
          <meta
            name="description"
            content="Online Learning สำนักวิทยบริการ มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์"
          />
          <link rel="canonical" href="/google" />
        </Helmet>
        <div className="p-5 mt-3 bg-warning" id="google-calendar">
          <h5 className="text-center p-3 ">ขั้นตอนการสร้าง Google Calendar</h5>
          <div className="row">
            <Fade bottom>
              <div className="col-lg-4 col-md">
                <div className="card">
                  <img
                    src={gCalendar1}
                    alt="google calendar"
                    className="rounded shadow-lg img-fluid"
                  />
                  <div className="card-body"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md d-flex justify-content-center align-items-center">
                <i
                  class="fa-solid fa-circle-right"
                  style={{ fontSize: "100px", color: "white" }}
                ></i>
              </div>
              <div className="col-lg-4 col-md mt-3">
                <div className="card">
                  <img
                    src={gCalendar2}
                    alt="google calendar"
                    className="rounded shadow-lg img-fluid"
                  />
                  <div className="card-body"></div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </>
    );
}

export default GoogleCalendar