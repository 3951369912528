import React, { useState } from "react";

import Welcome from "./Welcome";
import { useCookies } from "react-cookie";
import { Helmet } from 'react-helmet-async'
import Fade from 'react-reveal/Fade'

const Home = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [isOpen, setIsOpen] = useState(true)

  function handleSetCookie() {
    setCookie("user", "obydul", { path: "/" });
    setIsOpen(false);
  }

  function handleRemoveCookie() {
    removeCookie("user");
    setIsOpen(false);
  }

  return (
    <>
      <Helmet>
        <title>Online Learning RMUTSV</title>
        <meta
          name="description"
          content="Online learning สำนักวิทยบริการ มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์"
        />
        <meta
          name="description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta name="robots" content="nofollow, noindex" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="หน้าแรก - RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta
          property="og:description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta
          property="og:url"
          content="https://www.online-learning.rmutsv.ac.th/"
        />
        <meta
          property="og:site_name"
          content="RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta property="og:updated_time" content="2022-05-12T09:28:38+07:00" />
        <meta
          property="article:published_time"
          content="2020-03-19T18:48:33+07:00"
        />
        <meta
          property="article:modified_time"
          content="2022-05-12T09:28:38+07:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="หน้าแรก - RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta
          name="twitter:description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="admin" />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="Less than a minute" />
        <link rel="canonical" href="/" />
      </Helmet>
      {/* <Fade bottom> */}
      {/* {isOpen && (
          <div className="container mt-3 mb-2 ">
            <div className="cookie-section p-2">
              <h6 className="text-light">"เว็บไซต์นี้ใช้คุกกี้"</h6>
              <small className="text-light p-3 rounded">
                เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้เว็บไซต์
                <a href="/cookies" className="text-warning m-2 text-decoration-none"> นโยบายคุ๊กกี้</a>
              </small>
              <div className="container">
                <button
                  onClick={handleSetCookie}
                  className="btn btn-dark  btn-sm"
                >
                  ยอมรับ
                </button>
              </div>
              <div className="mb-2">
                <button
                  onClick={handleSetCookie}
                  className="btn btn-danger btn-sm"
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
            </div>
          </div>
        )} */}
      {/* </Fade> */}
      <Welcome />
    </>
  );
};

export default Home;
