import React from 'react'

const Modal = ({ closeModal, imgUrl }) => {
    return (
        <div className="App-header" style={{ position: 'fixed', top: '0', left: '0', backgroundColor: 'rgba(0,0,0,0.9)', height: '100vh', width: '100vw', zIndex: '9999' }} onClick={() => closeModal(false)}>
                <i className="fa-solid fa-circle-xmark text-danger  img-meet" onClick={() => closeModal(false)}></i>
            <div className="d-flex justify-content-end align-items-end mb-3">
                {/* <h1 className='text-light bg-dark rounded'>This is Modal</h1> */}

                {/* <button className='btn btn-sm btn-danger p-2 ms-auto ' style={{borderRadius:'50%',width:'40px'}} onClick={() => closeModal(false)}>X</button> */}

            </div>
            <div className="col-lg-12 col-md d-flex justify-content-center align-items-center">
                <img src={imgUrl} alt="Google Meet" width="30%" className='img-popup' />
            </div>

        </div>
    )
}

export default Modal