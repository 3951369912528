import React from 'react'
import MicrosoftLogo from '../images/Microsoft-Logo.png'
import googleMicrosoftTeacher from '../images/google_microsoft_teacher.png'
import mCloud from '../images/mCloud.png'
import mOutlook from '../images/mOutlook.png'
import mOffice from '../images/mOffice.png'
import mTeam from '../images/mTeam.png'
import wwwIcon from '../images/wwwIcon.png'
import messengerIcon from '../images/messengerIcon.png'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

function Microsoft() {
  return (
    <>
      <Helmet>
        <title>Google tools</title>
        <meta
          name="description"
          content="Online Learning Microsoft Team Microsoft account Login สำนักวิทยบริการ มหาวิทยาลัยราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์"
        />
        <link rel="canonical" href="/google" />
      </Helmet>

      <div className="container py-5">
        <div className="py-5">
          <div className="row mt-3 py-5">
            <div className="col-lg-6 col-md text-center mb-3">
              <img
                src={MicrosoftLogo}
                alt="microsoft logo"
                width={150}
                className="rounded shadow"
              />
              <h4
                className="text-muted mt-3"
                style={{ textShadow: "0 0 10px  rgba(0,0,0,0.5)" }}
              >
                Microsoft
              </h4>
            </div>
            <div className="col-lg-6 col-md">
              <div className="col d-flex justify-content-between align-items-center">
                <img src={mOutlook} alt="" width={80} className="img-hover" />
                <img src={mOffice} alt="" width={80} className="img-hover" />
                <img src={mCloud} alt="" width={80} className="img-hover" />
                <Link to="/team" className="nav-link">
                  <img src={mTeam} alt="" width={80} className="img-hover" />
                </Link>
              </div>
              <h4
                className="text-dark mt-3 text-center"
                style={{ textShadow: "0 0 6px  rgba(0,0,0,0.5)" }}
              >
                @ms.rmutsv.ac.th
              </h4>

              <hr />
              <div
                className="p-3 shadow arit-bg text-light"
                style={{
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <div className="mt-2">
                  <p className="text-center">
                    ยืนยันสิทธิ์การเข้าใช้งาน Microsoft Office 365 Education
                    <br />
                    ด้วยบัญชีผู้ใช้งานอินเตอร์เน็ต (RMUTSV e-Passport) <br />
                    ที่เว็บไซต์
                    <a href="https://ms.rmutsv.ac.th" target="_blank" rel="noreferrer" className="nav-link">
                      https://ms.rmutsv.ac.th
                    </a>{" "}
                    <br />
                    <br />
                    หลังจากยืนยันสิทธิ์ เรียบร้อยแล้ว เข้าเว็บไซต์
                    <br />
                    https://portal.office.com/
                    <br />
                    ลงชื่อเข้าใช้:(e-Passport) xxx.x@ms.rmutsv.ac.th
                    <br />
                    รหัสผ่าน : RMUTSV e-Passport
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <img src={wwwIcon} alt="" /> */}
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-3 col-md bg-light m-2 rounded shadow">
              <div className="mt-2 p-2 text-center  ">
                {/* <img src={wwwIcon} alt="www" width="50"/> */}
                <img src={wwwIcon} alt="www" width="50" />
                <a
                  href="https://arit.rmutsv.ac.th"
                  className="nav-link text-dark hover-underline-animation"
                >
                  arit.rmutsv.ac.th
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md bg-light m-2  rounded shadow">
              <div className="mt-2 p-2 text-center">
                <img src={messengerIcon} alt="messenger" width="50" />
                <a
                  href="https://www.facebook.com/RMUTSV.ARIT"
                  className="nav-link text-dark hover-underline-animation"
                >
                  fb.com/RMUTSV.ARIT
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md">
            <div>
              <p className="bg-warning p-3 mt-2 mb-2 text-center rounded-pill shadow">
                <i class="fa-brands fa-microsoft"></i> Microsoft Office 365
                Education
              </p>
              <ul className="list-group">
                <li className="list-group-item list-group-item-action ">
                  <a
                    href="https://ms.rmutsv.ac.th"
                    className="nav-link hover-underline-animation text-dark"
                  >
                    <i className="fa-solid fa-circle-check text-success "></i>{" "}
                    ยืนยันสิทธิ์ ครั้งแรกก่อนเข้าใช้งานการเข้าใช้งาน
                    https://ms.rmutsv.ac.th
                  </a>
                </li>
                <li className="list-group-item list-group-item-action ">
                  <a
                    href="https://ms.rmutsv.ac.th"
                    className="nav-link hover-underline-animation text-dark"
                  >
                    <i className="fa-solid fa-circle-check text-success "></i>{" "}
                    ดาวน์โหลดไฟล์ PDF{" "}
                    <i className="fa fa-file-pdf text-info"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Microsoft