import React from 'react'

const GoogleClassRoom = () => {
    return (
        <div>
            <p className="bg-warning text-center rounded p-3" id="google-class-room">
                แนะนำการใช้ Google Classroom สำหรับอาจารย์
            </p>

            <div className="col-lg col-md text-center">
                <iframe width="100%" height="450" src="https://www.youtube.com/embed/videoseries?list=PLSlHAycZ_JIEY8ODVeDb7FyJshhc15uQG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default GoogleClassRoom