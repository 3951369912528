import React from "react";
import { Link } from 'react-router-dom'

function Support() {
  return (
    <div className="container support">
      <div className="row">
        <div className="col-lg-3 col-md"></div>
        <div className="col-lg-3 col-md">
          <div className="card shadow p-2 support-card">
            <img
              className="card-img-top"
              src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/2020-05-20_9-43-29.jpg"
              alt="Card  cap"
            />
            <div className="card-body">
              <h5 className="card-title text-center text-success p-3">
                <Link
                  to="/student"
                  className="btn btn-outline-dark"
                  aria-current="page"
                >
                  คู่มือการใช้งานสำหรับนักศึกษา
                </Link>
              </h5>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md">
          <div className="card shadow p-2 support-card">
            <img
              className="card-img-top"
              src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/2020-05-20_9-44-02.jpg"
              alt="Card  cap"
            />
            <div className="card-body">
              <h5 className="card-title text-center text-success  p-3">
                <Link
                  to="/teacher"
                  className="btn btn-outline-dark"
                  aria-current="page"
                >
                  คู่มือการใช้งานสำหรับอาจารย์
                </Link>
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md"></div>
      </div>
    </div>
  );
}

export default Support;
