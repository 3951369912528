import React from 'react'

const Download = () => {
    return (
        <>
            <div className="container py-5 mt-5 mb-3">
                <div className="row">
                    <div className="col-lg-12 col-md mt-3">
                        <p className='bg-warning p-3 rounded shadow text-center'><i className="fa fa-download"></i>{' '}ดาวน์โหลดเอกสาร</p>
                        <ul>
                            <li className='list-group-item list-group-item-action'><a href="https://arit.rmutsv.ac.th/sites/default/files/data/IDB6U40FRvdkrUOWD7APGbtj6Mg4XPPMkuwnCfl1-300463.pdf" className='nav-link text-success hover-underline-animation'><i className="fa-solid fa-circle-check text-success"></i> คู่มือมาตรฐานการรักษาความปลอดภัยของการประชุมผ่านสื่ออิเล็กทรอนิกส์ <i className="fa fa-file-pdf text-info"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Download