import React,{useState} from 'react'
import gDrive1 from '../images/gDrive1.jpg'
import gDrive2 from '../images/gDrive2.jpg'
import gDrive3 from '../images/gDrive3.jpg'
import Modal from './Modal';
import Fade from 'react-reveal/Fade'
const GDriveLms = () => {
  const [openModal, setOpenModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  return (
    <>
      <h5 className="text-center bg-warning p-3 mb-2 rounded shadow">
        <i class="fa-brands fa-google-drive"></i> วิธีการดึงวีดีโอ จาก Google
        Drive มาใช้งานบน LMS
      </h5>
      <div className="row">
        <Fade bottom>
          <div className="col-lg-4 col-md">
            <img
              src={gDrive1}
              className="img-fluid thumbnail rounded shadow img-meet"
              alt=""
              onClick={() => {
                setOpenModal(true);
                setImgUrl(gDrive1);
              }}
            />
          </div>
        </Fade>
        <Fade bottom>
          <div className="col-lg-4 col-md">
            <img
              src={gDrive2}
              className="img-fluid thumbnail rounded shadow img-meet"
              alt=""
              onClick={() => {
                setOpenModal(true);
                setImgUrl(gDrive2);
              }}
            />
          </div>
        </Fade>

        <div className="col-lg-4 col-md">
          {/* <Fade bottom> */}
            <img
              src={gDrive3}
              className="img-fluid thumbnail rounded shadow img-meet"
              alt=""
              onClick={() => {
                setOpenModal(true);
                setImgUrl(gDrive3);
              }}
            />
          {/* </Fade> */}
          {openModal && <Modal closeModal={setOpenModal} imgUrl={imgUrl} />}
        </div>
      </div>
    </>
  );
}

export default GDriveLms