import React, { useState } from 'react'
import gMail from '../images/gmail_icon-icons.com_62758.png'
import googleMeet from '../images/googleMeet.png'
import gmailStudent from "../images/gmail_for_student.jpg"
import gmailTeacher from "../images/gmail_for_teacher.jpg"
import gMeetConf from "../images/gMeetConf.jpg"
import gSuite from '../images/gSuite.jpg'
import gCalen from '../images/gCalen.jpg'
import Fade from 'react-reveal/Fade'
// import { Link } from 'react-router-dom';
import Modal from './Modal'


const GoogleMeet = () => {
    const [openModal, setOpenModal] = useState(false);
    const [imgUrl, setImgUrl] = useState('');


    return (
      <div className="mt-3 rounded shadow p-3 m-3" id="google-mail">
        <div className="row">
          <h6>การเข้าใช้งาน e-mail</h6>
          <Fade bottom>
            <div
              className="col d-flex bg-light text-dark rounded mb-3 p-5 justify-content-center align-items-center"
              id="google-meet"
            >
              <img
                src={gMail}
                width={50}
                height={50}
                alt="gMail"
                className="thumbnail"
              />
              <ul>
                <img
                  src={gmailTeacher}
                  alt="gmailforstudent"
                  width={500}
                  className="rounded shadow mb-3 img-fluid"
                />
                <li className="list-unstyled text-center">
                  <a
                    className="text-success"
                    style={{ textDecoration: "none" }}
                    href="http://gmail.rmutsv.ac.th"
                  >
                    <i class="fa-regular fa-circle-check"></i>
                    http://gmail.rmutsv.ac.th{" "}
                  </a>
                  <br />
                  สำหรับบุคลากร @rmutsv.ac.th
                </li>
                <hr />
                <img
                  src={gmailStudent}
                  alt="gmailforstudent"
                  width={500}
                  className="rounded shadow mb-3 img-fluid"
                />
                <li className="list-unstyled text-center">
                  <a
                    className="text-success"
                    style={{ textDecoration: "none" }}
                    href="http://mail.rmutsvmail.com"
                  >
                    <i class="fa-regular fa-circle-check"></i>
                    http://mail.rmutsvmail.com{" "}
                  </a>
                  <br />
                  สำหรับนักศึกษา @rmutsvmail.com
                </li>
              </ul>
            </div>
          </Fade>

          <Fade bottom>
            <div className="col">
              <h6>การสร้างห้องเรียน (Google Meet) </h6>
              <div className="col d-flex justify-content-center align-items-center">
                <img
                  src={googleMeet}
                  width={50}
                  height={50}
                  alt="gMail"
                  className="thumbnail"
                />
                <ul>
                  <li className="list-unstyled">
                    <i className="fa-regular fa-circle-check"></i>
                    <a
                      className="text-success"
                      style={{ textDecoration: "none" }}
                      href="https://meet.google.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://meet.google.com
                    </a>
                    <br />
                    สมัครและตรวจสอบ e-mail ของนักศึกษา @rmutsvmail.com
                  </li>
                  <i class="fa-solid fa-arrow-right"></i>
                  <a
                    className="text-success"
                    style={{ textDecoration: "none" }}
                    href="https://e-service.rmutsv.ac.th/regmail/"
                  >
                    https://e-service.rmutsv.ac.th/regmail/
                  </a>
                </ul>
              </div>
              <hr />
              <h6>การสร้างตารางนัดหมาย/เรียนออนไลน์</h6>
              <div className="col d-flex justify-content align-items-center">
                <ul>
                  <li className="list-unstyled">
                    <i className="fa-regular fa-circle-check"></i> ปฏิทิน{" "}
                    <a
                      href="https://calendar.google.com/"
                      className="text-success"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      https://calendar.google.com/
                    </a>
                  </li>
                </ul>
              </div>
              <h6>บันทึกการสอนออนไลน์</h6>
              <small className="text-muted">
                ลงบน Google Drive แล้วนำ VIDEO ที่บันทึกการสอน ลงใน LMS หรือแชร์
                เพื่อให้นักศึกษาทบทวนบทเรียนย้อนหลัง
              </small>
              <div className="col d-flex justify-content align-items-center">
                <ul>
                  <li className="list-unstyled">
                    <i className="fa-regular fa-circle-check"></i>
                    Google Drive{" "}
                    <a
                      href="https://drive.google.com/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      className="text-success"
                    >
                      https://drive.google.com/
                    </a>
                  </li>
                </ul>
              </div>
              <hr />

              <div className="text-center m-2">
                <div className="rounded google-meet-card">
                  <img
                    src="https://www.gstatic.com/meet/user_edu_get_a_link_light_90698cd7b4ca04d3005c962a3756c42d.svg"
                    alt="googlemeet"
                    width={100}
                    height={100}
                  />
                  <p>
                    รับลิ้งที่แชร์กับผู้อื่นได้{" "}
                    <small className="text-muted">
                      คลิกการประชุมใหม่เพื่อรับลิ้งก์ที่คุณจะส่งให้ผู้ที่ต้องการประชุมด้วยได้
                    </small>
                  </p>
                </div>
                <div className=" rounded google-meet-card">
                  <img
                    src="https://www.gstatic.com/meet/user_edu_brady_bunch_light_81fa864771e5c1dd6c75abe020c61345.svg"
                    alt="meet"
                    width={100}
                    height={100}
                  />
                  <p>
                    เห็นทุกคนพร้อมกัน{" "}
                    <small className="text-muted">
                      หากต้องการเห็นผู้เข้าร่วมจำนวนมากขึ้นในเวลาเดียวกัน
                      โปรดไปที่ "เปลี่ยนเลย์เอาต์" ในเมนู "ตัวเลือกเพิ่มเติม"
                    </small>
                  </p>
                </div>
                <div className="rounded google-meet-card">
                  <img
                    src="https://www.gstatic.com/meet/user_edu_scheduling_light_b352efa017e4f8f1ffda43e847820322.svg"
                    alt="meet"
                    width={100}
                    height={100}
                  />
                  <p>
                    วางแผนล่วงหน้า{" "}
                    <small className="text-muted">
                      คลิกการประชุมใหม่เพื่อกำหนดการประชุมใน Google
                      ปฏิทินและส่งคำเชิญให้ผู้เข้าร่วม
                    </small>
                  </p>
                </div>
                <div className="rounded google-meet-card">
                  <img
                    src="https://www.gstatic.com/meet/user_edu_safety_light_e04a2bbb449524ef7e49ea36d5f25b65.svg"
                    alt="meet"
                    width={100}
                    height={100}
                  />
                  <p>
                    การประชุมของคุณปลอดภัย{" "}
                    <small className="text-muted">
                      เนื่องจากไม่มีใครเข้าร่วมประชุมได้นอกจากจะได้รับเชิญหรือได้รับอนุญาตจากผู้จัด
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Fade bottom>
          <div className="row mt-3 text-center" id="google-meet-conf">
            <div className="col-lg-12 col-md d-flex justify-content-center align-items-center ">
              <img
                src={googleMeet}
                width={50}
                height={50}
                alt="gMail"
                className="shadow rounded m-2"
              />
              <h6 className="p-3 m-2 text-dark bg-warning  rounded shadow">
                การจัดการเรียนการสอนออนไลน์ Google Meet
              </h6>
              <a
                href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/goooglemeet.pdf"
                className="btn btn-success btn-lg"
              >
                <i className="fa fa-download"></i> ดาวโหลด PDF
              </a>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="row">
            <div className="col-lg-4">
              <img
                src={gMeetConf}
                alt="google Meet Conference"
                className="rounded img-meet shadow-lg mt-2 m-2 img-fluid"
                onClick={() => {
                  setOpenModal(true);
                  setImgUrl(gMeetConf);
                }}
              />
            </div>
            <div className="col-lg-4">
              <img
                src={gSuite}
                alt="google Meet Conference"
                className="rounded img-meet shadow-lg mt-2 m-2 img-fluid"
                onClick={() => {
                  setOpenModal(true);
                  setImgUrl(gSuite);
                }}
              />
            </div>
            <div className="col-lg-4">
              <img
                src={gCalen}
                alt="google Meet Conference"
                className="rounded img-meet shadow-lg mt-2 m-2 img-fluid"
                onClick={() => {
                  setOpenModal(true);
                  setImgUrl(gCalen);
                }}
              />
            </div>
            {openModal && <Modal closeModal={setOpenModal} imgUrl={imgUrl} />}
          </div>
        </Fade>
      </div>
    );
}

export default GoogleMeet