import React from 'react'
// import GoogleLogo from '../images/google.png'
// import Support from './Support'
// import googleCloud from '../images/googleCloud.png'
import googleMail from "../images/gmail_icon-icons.com_62758.png";
import googleDrive from "../images/googleDrive.png"
import googleClassRoom from "../images/googleClassRoom.png"
import googleMeet from "../images/googleMeet.png"
import GoogleMeet from './GoogleMeet';
// import { Link } from 'react-router-dom'
import GoogleClassRoom from './GoogleClassRoom';
import { Helmet } from 'react-helmet-async'
import GoogleCalendar from './GoogleCalendar';
import Fade from 'react-reveal/Fade'


function Google() {
  return (
    <>
      <Helmet>
        <title>Google tools</title>
        <meta
          name="description"
          content="Online Learning สำนักวิทยบริการ มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์"
        />
        <link rel="canonical" href="/google" />
      </Helmet>

      <div className="container support" id="top">
        <nav className="nav navbar-expand-sm google-nav mb-3 p-3 shadow rounded nav-sub">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item ">
              <a
                className="btn btn-warning m-2 hover-underline-animation"
                aria-current="page"
                href="#google-mail"
              >
                การเข้าใช้งาน e-mail
              </a>
            </li>

            <li className="nav-item ">
              <a
                className="btn btn-warning m-2 hover-underline-animation"
                aria-current="page"
                href="#google-class-room"
              >
                แนะนำการใช้งาน Google Classroom สำหรับอาจารย์
              </a>
            </li>
            <li className="nav-item ">
              <a
                className="btn btn-warning m-2 hover-underline-animation"
                aria-current="page"
                href="#google-meet-conf"
              >
                Google Meet
              </a>
            </li>
            <li className="nav-item ">
              <a
                className="btn btn-warning m-2 hover-underline-animation"
                aria-current="page"
                href="#google-calendar"
              >
                Google Calendar
              </a>
            </li>
          </ul>
        </nav>

        <div className="top">
          <a href="#top" className="nav-link active">
            <i className="fa-solid fa-circle-arrow-up text-light"></i>
          </a>
        </div>
        <Fade bottom>
          <div className="row">
            {/* <div className="col-lg-3 col-md"></div> */}
            <Fade bottom>
              <div className="col-lg-4 col-md">
                <div className="card shadow p-2 support-card">
                  <a
                    href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/Google_Classroom_Student.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      className="card-img-top"
                      src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/2020-05-20_9-43-29.jpg"
                      alt="Card cap"
                    />
                  </a>
                  <div className="card-body">
                    <h5 className="card-title text-center text-success p-3">
                      <a
                        href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/Google_Classroom_Student.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-outline-dark"
                        aria-current="page"
                      >
                        คู่มือการใช้งานสำหรับนักศึกษา
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-lg-4 col-md">
                <div className="card shadow p-2 support-card">
                  <a
                    href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/Google_Classroom_Teacher.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="card-img-top"
                      src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/2020-05-20_9-44-02.jpg"
                      alt="Card  cap"
                    />
                  </a>
                  <div className="card-body">
                    <h5 className="card-title text-center text-success  p-3">
                      <a
                        href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/Google_Classroom_Teacher.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-outline-dark"
                        aria-current="page"
                      >
                        คู่มือการใช้งานสำหรับอาจารย์
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </Fade>
            <div className="col-lg-4 col-md">
              {/* <img src={googleCloud} width ="150" alt="google cloud" /> */}
              <div className="d-flex justify-content-center align-items-center">
                <a href="#google-mail">
                  <img
                    src={googleMail}
                    alt=""
                    width="50"
                    className="m-2 shadow rounded-pill"
                  />
                </a>
                <img src={googleDrive} alt="" width="50" className="m-2 " />
                <a href="#google-class-room">
                  <img
                    src={googleClassRoom}
                    alt=""
                    width="50"
                    className="m-2 shadow rounded-pill"
                  />
                </a>
                <a href="#google-mail">
                  <img src={googleMeet} alt="" width="50" className="m-2  " />
                </a>
              </div>
              <div className="text-center">
                <small>@rmutsvmail.com</small>
              </div>
              <hr />
              <h6>การเข้าใช้งาน Account Google สำหรับนักศึกษา</h6>
              <div className="arit-bg text-light p-3 rounded shadow">
                <p>
                  <ul>
                    <li>เข้าใช้งาน @rmutsvmail.com</li>
                    <li>
                      เข้าเว็บ{" "}
                      <a
                        href="http://mail.rmutsvmail.com"
                        target="_blank"
                        rel="noopener"
                        className="text-success"
                      >
                        http://mail.rmutsvmail.com{" "}
                      </a>
                    </li>
                    <li>rmutsvmail:ชื่ออีเมลไม่ต้องใส่ @rmutsvmail.com </li>
                    <li>รหัสผ่าน : RMUTSV e-Passport </li>

                    <li
                      className="text-warning"
                      style={{ listStyle: "none", fontSize: "small" }}
                    >
                      ตรวจสอบ e-mail @rmutsvmail.com
                      <br />
                      hppts://e-service.rmutsv.ac.th/regmail/
                    </li>
                  </ul>
                </p>
              </div>
              <hr />
              <h6>การเข้าใช้งาน Account Google บุคลากร</h6>
              <div className="arit-bg text-light p-3 rounded shadow">
                <p>
                  <ul>
                    <li>เข้าใช้งาน @rmutsvmail.com</li>
                    <li>เข้าเว็บ https://gmail.rmutsvmail.ac.th </li>
                    <li>ชื่อผู้ใช้ : (e-Passport) xxx.x </li>
                    <li>ไม่ต้องใส่@rmutsv.ac.th</li>
                    <li>รหัสผ่าน : RMUTSV e-Passport </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </Fade>
        <div className="container-fluid">
          
            <GoogleMeet />
          
          <Fade bottom>

          <GoogleClassRoom />
          </Fade>
        </div>
      </div>

      <GoogleCalendar />
    </>
  );
}

export default Google