import React from 'react'
import lmsIntro from '../images/001.png'
import moodle from '../images/003.png'
import googleMeet from '../images/002.png'
import Logo from '../images/logo.png'
import GDriveLms from './GDriveLms'
import Fade from 'react-reveal/Fade'
// import GoogleMeet from './GoogleMeet'

const aLink = {
    textDecoration: 'none'
}
const Lms = () => {
    return (
      <div className="container mt-5 py-5">
        <Fade bottom>
          <div className="row mt-3 py-5">
            <div className="col-lg-4 col-sm">
              <div className="text-center">
                <img src={moodle} className="img-fluid " alt="" width={250} />
              </div>

              <ul className="list-group">
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=47"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    คู่มือการใช้งาน LMS สำหรับอาจารย์ผู้สอน
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=48&"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    คู่มือการใช้งานสำหรับนักศึกษา
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81188"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การจัดการเนื้อหา
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=41544"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    อนุญาตเข้าเรียน และจัดการกลุ่มผู้เรียน
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81206"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การสร้างกิจกรรมและติดตามประเมินผล
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81208"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การสื่อสารและปฏิสัมพันธ์ระหว่างผู้เรียนและผู้สอน
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81052"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การนำสื่อการสอน (Video) จาก Google Drive มาประยุกต์ใช้งาน
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81808"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การตั้งค่ารหัสลงทะเบียนเข้าเรียน Set Enrolment key
                    สำหรับอาจารย์
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81841"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การเข้าเรียนในรายวิชา Enrollment in courses สำหรับนักศึกษา
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-sm">
              <div className="text-center">
                <img src={lmsIntro} className="img-fluid" alt="" width={300} />
              </div>
              <div className="row">
                <div className="col text-center">
                  <img src={Logo} className="img-fluid" alt="" width={300} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm">
              <div className="text-center">
                <img src={googleMeet} width={250} alt="" />
              </div>
              <ul className="list-group">
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>
                  <a
                    href="http://meet.google.com/"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    เว็บไซต์ http://meet.google.com
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.meetings"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    โทรศัพท์ Android
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://apps.apple.com/us/app/hangouts-meet-by-google/id1013231476"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    โทรศัพท์ iOS
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://arit.rmutsv.ac.th/sites/default/files/data/google-meet.pdf"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    คู่มือการใช้งาน Google Meet
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://www.online-learning.rmutsv.ac.th/2020/03/20/calendar-meet/"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    การสร้างตารางนัดหมายนักศึกษา เพื่อเรียนออนไลน์
                  </a>
                </li>
                <li className="list-group-item list-group-item-action">
                  <i className="fa-solid fa-circle-check text-success"></i>{" "}
                  <a
                    href="https://lms59.rmutsv.ac.th/mod/resource/view.php?id=81052"
                    className="text-dark"
                    style={aLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    วิธีการดึงวิดีโอจาก Google Drive
                    มาประยุกต์ใช้บนระบบการจัดการเรียนการสอนออนไลน์ LMS
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Fade>
        
          <GDriveLms />
        
        {/* <GoogleMeet /> */}
      </div>
    );
}

export default Lms