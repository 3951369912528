import React from 'react'
// import CookiesImage from '../images/cookie.png'
import './cookies.css'
function Cookies() {
    return (
        <div className="container" >
            <div className="row" style={{marginTop:'4rem'}}>
                <div className="col-lg col-sm my-5">
                    <p>
                        <h5>นโยบายการใช้คุกกี้ (Cookies Policy)</h5>
                        คุกกี้คืออะไร คุกกี้ (Cookies) คือ
                        ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังอุปกรณ์คอมพิวเตอร์หรืออุปกรณ์มือถือของท่าน
                        ซึ่งทำหน้าที่บันทึกข้อมูลและตั้งค่าต่าง ๆ เช่น
                        บันทึกข้อมูลการตั้งค่าภาษาบนอุปกรณ์ของท่าน
                        บันทึกสถานะการเข้าใช้งานปัจจุบัน เป็นต้น
                        เพื่อช่วยให้ท่านเข้าใช้งานเว็บไซต์และหรือแอปพลิเคชันได้อย่างต่อเนื่อง
                        (“บริการ”)
                        รวมถึงรวบรวมข้อมูลประวัติการเข้าใช้งานเว็บไซต์หรือบริการของมหาวิทยาลัยจากท่านในรูปแบบไฟล์
                        ซึ่งจะไม่ก่ออันตรายแก่อุปกรณ์ของท่านแต่ประการใด อนึ่ง
                        ท่านสามารถเรียกดูเนื้อหาของคุกกี้ได้ ผ่านบริการต่าง ๆ
                        ที่สร้างคุกกี้ดังกล่าวเท่านั้น คุกกี้มีประโยชน์อย่างไร
                        คุกกี้เป็นเครื่องมือบอกมหาวิทยาลัยให้ทราบว่า
                        ท่านเข้าใช้งานส่วนใดของเว็บไซต์หรือบริการของมหาวิทยาลัย
                        เพื่อมหาวิทยาลัยจะได้มอบประสบการณ์ในการรับบริการที่ดีขึ้นและตรง
                        ตามความต้องการของท่านมากที่สุด นอกจากนี้
                        การบันทึกการตั้งค่าแรกของบริการด้วยคุกกี้ยังช่วยให้ท่านเข้าถึงบริการด้วยค่าที่ตั้งไว้ทุกครั้งที่เข้าใช้งาน
                        เว้นแต่ว่าคุกกี้นั้นจะถูกลบ
                        ซึ่งจะทำให้การตั้งค่าทุกอย่างกลับไปยังค่าเริ่มต้น
                    </p>
                    <hr />
                    <h5>คุกกี้ใช้งานอย่างไร </h5>
                    <p>
                        มหาวิทยาลัยจะใช้คุกกี้และเทคโนโลยีอื่น ๆ เช่น พิกเซลแท็ก (Pixel – tags)
                        บนบริการของมหาวิทยาลัย ดังนั้น บริการใด ๆ
                        ที่ท่านใช้เพื่อเข้าถึงบริการจะได้รับคุกกี้ที่มหาวิทยาลัยสร้างเสมอ
                    </p>
                    <p>
                        เมื่อท่านเข้าถึงบริการแล้ว
                        คุกกี้จะถูกดาวน์โหลดไปยังอุปกรณ์ของท่านเพื่อเก็บรวบรวมข้อมูลเกี่ยวกับประวัติการเข้ารับบริการ
                        รูปแบบการใช้งาน และข้อมูลหรือบริการที่ท่านสนใจ
                        รวมถึงหมายเลขอ้างอิงการเข้ารับบริการของท่าน นอกจากนี้
                        มหาวิทยาลัยยังได้ใช้งานคุกกี้ร่วมกับเทคโนโลยีพิกเซลแท็กสำหรับการวิเคราะห์รูปแบบการใช้งานและข้อมูลหรือบริการที่ท่านสนใจ
                        เพื่อพัฒนาการให้บริการ แสดงเนื้อหาหรือโฆษณา
                        หรือประชาสัมพันธ์กิจกรรมหรือบริการต่าง ๆ
                        ที่ต้องตรงตามความสนใจของท่าน ทั้งนี้
                        เพื่อพัฒนาบริการและสร้างความพึงพอใจแก่ท่านมากยิ่งขึ้น อย่างไรก็ดี
                        มหาวิทยาลัยตกลงจะใช้งานคุกกี้ตามรายละเอียดที่กำหนดไว้ในคำประกาศเกี่ยวกับความเป็นส่วนตัว
                        (Privacy Notice) เท่านั้น </p>
                    <hr />
                    <h5>สำหรับประเภทคุกกี้ที่มหาวิทยาลัยใช้งานสามารถแบ่งออกเป็น 2 ประเภท ดังนี้</h5>
                    <p> 1. คุกกี้เพื่อการให้บริการ
                        คุกกี้เพื่อการให้บริการนี้
                        จะช่วยให้ท่านสามารถใช้บริการและเข้าถึงฟังก์ชันต่าง ๆ ได้ดียิ่งขึ้น
                        เช่น คุกกี้ที่จัดการการเข้าสู่ระบบและสถานะการเข้าสู่ระบบ เป็นต้น
                        <br /> 2.
                        คุกกี้เพื่อการเสริมประสิทธิภาพการให้บริการ
                        คุกกี้เพื่อการเสริมประสิทธิภาพการให้บริการนี้
                        จะช่วยอำนวยความสะดวกแก่ท่านในการเข้ารับบริการ
                        และยังทำให้มหาวิทยาลัยรู้จักท่านมากยิ่งขึ้น
                        โดยมหาวิทยาลัยจะใช้ข้อมูลดังกล่าวนี้ในการนำเสนอและพัฒนาบริการของมหาวิทยาลัยให้ต้องตรงความสนใจของท่านต่อไป
                        เช่น คุกกี้จัดการจำสถานะการเข้ารับบริการ คุกกี้จัดการบริการ
                        LiveChate หรือฟังก์ชัน Social Media Sharing บนเว็บไซต์ เป็นต้น
                        <hr />
                        <h5>คุกกี้ปิดการทำงานอย่างไร</h5>
                        ท่านสามารถปิดการทำงานของคุกกี้ได้โดยการตั้งค่าเบราว์เซอร์ของท่าน
                        และตั้งค่าความเป็นส่วนตัว
                        เพื่อระงับการรวบรวมข้อมูลในอนาคตโดยคุกกี้นี้ อย่างไรก็ตาม
                        บริการบางอย่างบนเว็บไซต์ของมหาวิทยาลัยอาจจะเป็นจะต้องใช้งานคุกกี้
                        หากท่านพิจารณาปิดการงานคุกกี้แล้ว
                        อาจทำให้ฟังก์ชันบางส่วนหรือทั้งหมดของบริการนั้น ๆ
                        สะดุดหรือไม่ราบรื่นได้
                    </p>
                    {/* <img src={CookiesImage} className="img-fluid rounded-2 shadow-lg mb-2" alt="Cookie Policy" /> */}
                </div>
            </div>
        </div>
    );
}

export default Cookies