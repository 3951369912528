import React from 'react';
import YoutubeDoc from '../images/youtubeDoc.png'
import { Helmet } from 'react-helmet-async'

const Youtube = () => {
    return (
        <>
            <Helmet>
                <title>Google tools</title>
                <meta name="description" content='Online Learning สำนักวิทยบริการ มหาวิทยาลัยราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือขั้นตอนการใช้งาน Youtube ด้วย Google Account' />
                <link rel="canonical" href="/youtube" />
            </Helmet>

            <div className="container py-5">
                <div className="row mt-3">
                    <div className="col-lg-6 col-md d-flex justify-content-center align-items-center mt-3 py-3" >
                        <img src={YoutubeDoc} width={400} alt="Youtube document" className='img-fluid rounded shadow-lg mt-3'  id="youtube-doc" />
                    </div>

                    <div className="col-lg-6 col-md d-flex justify-content-center align-items-center mt-3">
                        <p><a className="btn btn-danger btn-lg" href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2021/07/Youtube-RUTS.pdf"><i className="fa fa-download"></i>{' '}Download เอกสารคู่มือการใช้งาน Youtube</a></p>
                    </div>
                </div>

               
            </div>
        </>

    )
}

export default Youtube