// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import Home from './pages/Home'
import Google from './pages/Google';
import Nav from './pages/Nav';
import Zoom from './pages/Zoom';
import Microsoft from './pages/Microsoft';
import Support from './pages/Support';
import Footer from './pages/Footer';
import Student from './pages/Student';
import Teacher from './pages/Teacher';
import Lms from './pages/Lms';
import Youtube from './pages/Youtube';
import Download from './pages/Download';
import Cookies from './pages/Cookies';
import { Helmet } from "react-helmet-async";
import Team from './pages/Team';


// import {useState,useCookies} from "react"

function App() {
  // const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  // const [isOpen, setIsOpen] = useState(true)

  // function handleSetCookie() {
  //   setCookie("user", "obydul", { path: "/" });
  //   setIsOpen(false);
  // }

  // function handleRemoveCookie() {
  //   removeCookie("user");
  //   setIsOpen(false);
  // }
  return (
    <Router>
      <Helmet>
        <title>Online Learning RMUTSV</title>
        <meta
          name="description"
          content="Online learning สำนักวิทยบริการ มหาวิทยาลัยเทคโนโลยีราชมงคลศรีวิชัย ระบบสนับสนุนการเรียนการสอนออนไลน์"
        />
        <meta
          name="description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta name="robots" content="nofollow, noindex" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="หน้าแรก - RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta
          property="og:description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta
          property="og:url"
          content="https://www.online-learning.rmutsv.ac.th/"
        />
        <meta
          property="og:site_name"
          content="RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta property="og:updated_time" content="2022-05-12T09:28:38+07:00" />
        <meta
          property="article:published_time"
          content="2020-03-19T18:48:33+07:00"
        />
        <meta
          property="article:modified_time"
          content="2022-05-12T09:28:38+07:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="หน้าแรก - RUTS ศูนย์การเรียนรู้แบบออนไลน์"
        />
        <meta
          name="twitter:description"
          content="การเข้าใช้งาน Account Google &amp; Microsoft  ระบบสนับสนุนการเรียนการสอนออนไลน์ คู่มือการใช้งานสำหรับนักศึกษา คู่มือการบันทึกวีดีโอด้วยโปรแกรม ZOOM"
        />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="admin" />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="Less than a minute" />
        <link rel="canonical" href="/" />
      </Helmet>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/google" element={<Google />} />
        <Route path="/zoom" element={<Zoom />} />
        <Route path="/microsoft" element={<Microsoft />} />
        <Route path="/support" element={<Support />} />
        <Route path="/student" element={<Student />} />
        <Route path="/teacher" element={<Teacher />} />
        <Route path="/lms" element={<Lms />} />
        <Route path="/youtube" element={<Youtube />} />
        <Route path="/download" element={<Download />} />
        {/* <Route path="/cookies" element={<Cookies />} /> */}
        <Route path="/team" element={<Team />} />
      </Routes>

      {/* Text cookies */}

      {/* {isOpen && (
        <div className="container mt-3 mb-2 ">
          <div className="cookie-section p-2">
            <h6 className="text-light">"เว็บไซต์นี้ใช้คุกกี้"</h6>
            <small className="text-light p-3 rounded">
              เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้เว็บไซต์
              <a href="/cookies" className="text-warning m-2 text-decoration-none"> นโยบายคุ๊กกี้</a>
            </small>
            <div className="container">
              <button
                onClick={handleSetCookie}
                className="btn btn-dark  btn-sm"
              >
                ยอมรับ
              </button>
            </div>
            <div className="mb-2">
              <button
                onClick={handleSetCookie}
                className="btn btn-danger btn-sm"
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
          </div>
        </div>
      )} */}

      <Footer />
      {/* <MessengerCustomerChat
        // pageId="102543964919932"
        pageId="491818127505000"
        appId="5348602521830242"
        
        /> */}
    </Router>
  );
}

export default App;
