import React from 'react';
import Logo from '../images/arit_logo.png'

function Footer() {
  return (
    <div className="mt-3" id="footer">
      <div className="row bg-dark text-light">
        <div className="col-sm footer text-center">
          <img src={Logo} alt="" width="120" class="Logo" />
          <h5>สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</h5>
          <ul className="text-light">
            เลขที่ 1 ถนนราชดำเนินนอก ตำบลบ่อยาง อำเภอเมือง จังหวัดสงขลา 90000
            <br />
            โทรศัพท์ :: 0-7431-7146 โทรศัพท์ :: 0-7431-7100 ต่อ 1160 - 1161,
            3030 โทรสาร :: 0-7431-7147
            <br />
            www :: https://arit.rmutsv.ac.th <br />
            e-mail :: learning@rmutsv.ac.th
            <br />
            <i
              className="fa-brands fa-line text-success"
              style={{ fontSize: "25px" }}
            ></i>
            <a
              href="https://lin.ee/lVHqWSd"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              @lhp1366x
            </a>
            <i className="fa-brands fa-facebook text-primary" style={{ fontSize: "25px"}}></i>
            <a href="https://www.facebook.com/RMUTSV.ARIT" className="badge badge-primary text-wrap">RMUTSV.ARIT</a>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer