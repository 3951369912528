import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/logo.png'
function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top main-nav">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            alt="rmutsv"
            className="Logo"
          />{" "}
          RUTS ศูนย์การเรียนรู้แบบออนไลน์
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link active" aria-current="page">
                <i className="fa fa-home"></i> หน้าแรก
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/support" className="nav-link" aria-current="page">
                <i className="fa-solid fa-globe"></i>{" "}
                ระบบสนับสนุนการเรียนการสอนออนไลน์
              </Link>
            </li> */}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle "
                href='#'
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                rel="noreferrer"
              >
                <i className="fa-solid fa-chalkboard"></i>{' '}ระบบสนับสนุนการเรียนการสอนออนไลน์
              </a>
              <ul
                className="dropdown-menu  w-100"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link to="/google" className="dropdown-item">
                    <i className="fa-brands fa-google-plus-g text-primary"></i>
                    Google Tools
                  </Link>
                </li>
                {/* <li>
                  <a href="https://docs.google.com/presentation/d/10ptniorjoKiPtEbLSbdmBhPHHW6T0Iaomrp2WMVtwmI/edit#slide=id.g5a72886f1e_2_5" className='dropdown-item' target="_blank" rel="noreferrer"><i className="fa-brands fa-uncharted text-primary"></i>การใช้งาน G Suite for Education</a>
                </li> */}
                <li>
                  <Link to="/zoom" className="dropdown-item">
                    <i className="fa-solid fa-video text-info"></i>ZOOM Meeting
                  </Link>
                </li>
                <li>
                  <Link to="/microsoft" className="dropdown-item">
                    <i className="fa-brands fa-microsoft text-success"></i>
                    Microsoft Office 365
                  </Link>
                </li>
                <li>
                  <Link to="/youtube" className="dropdown-item" >
                    <i className="fa-brands fa-youtube text-danger"></i>
                    Youtube
                  </Link>
                </li>
                <li>
                  <a href="http://vpn.rmutsv.ac.th/" className='dropdown-item' target="_blank" rel='noreferrer'><i className="fa-solid fa-diagram-project"></i>RUTS VPN เครือข่ายเสมือน</a>
                </li>


                <li>
                  <a href="http://lib.rmutsv.ac.th/site/th/basic/139" className='dropdown-item' target="_blank" rel='noreferrer'><i className="fa-solid fa-globe"></i>รวมแหล่งทรัพยากรออนไลน์</a>
                </li>

              </ul>
            </li>
            <li>
              <div className="d-flex align-itmes-center">
                {/* <img src={Logo} width="75" alt="" /> */}
                <Link to="/lms" className='nav-link'><i className="fa-solid fa-list"></i> คู่มือ LMS & Google Meet</Link>
              </div>
            </li>
            <li>
              <Link to="/download" className='nav-link'><i className="fa fa-download"></i> Download</Link>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav