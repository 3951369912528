import React from 'react'
// import GoogleLogo from '../images/google.png'
// import Support from './Support'
import { Link } from 'react-router-dom'
function Google() {
  return (
    <div className="container support">
      <div className="row">
        <div className="col-lg-3 col-md"></div>
        <div className="col-lg-3 col-md">
          <div className="card shadow p-2 support-card">
            <img
              className="card-img-top"
              src="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/2020-05-20_9-43-29.jpg"
              alt="Online Learning"
            />
            <div className="card-body">
              <h5 className="card-title text-center text-success p-3">
                <Link
                  to="/student"
                  className="btn btn-outline-dark"
                  aria-current="page"
                >
                  คู่มือการใช้งานสำหรับนักศึกษา
                </Link>
              </h5>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md d-flex justify-content-center align-items-center">
          <div className="card shadow p-2 support-card">

            
            <div className="card-body">
              <h5 className="card-title text-center text-success  p-3">
                <i className="fa fa-download text-success"><h6><a className='nav-link text-success hover-underline-animation' href="https://www.online-learning.rmutsv.ac.th/wp-content/uploads/2020/05/Google_Classroom_Student.pdf">Download เอกสารคู่มือสำหรับนักศึกษา</a></h6></i>
              </h5>
            </div>
            
          </div>
        </div>
        <div className="col-lg-3 col-md"></div>
      </div>
    </div>
  )
}

export default Google